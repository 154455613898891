// hint-mixins.scss
//
// Place to store common mixins.

// Vendor prefixer mixin.
@mixin vendor($property, $value) {
	-webkit-#{$property}: $value;
	-moz-#{$property}: $value;
	#{$property}: $value;
}

// Generates border-color rules for all possible positions
@mixin arrow-border-color($color) {
	@each $position in top, bottom, left, right {
		@if $position == top or $position == bottom {
			// Loop further for classes like .top-left, bottom-right etc
			@each $xDir in left, right {
				&.#{$hintPrefix}#{$position}-#{$xDir}:before {
					border-#{$position}-color: $color;
				}
			}
		}
		&.#{$hintPrefix}#{$position}:before {
			border-#{$position}-color: $color;
		}
	}
}

// mixin to set margin on tooltip using translate transform
// $property
@mixin set-margin($property, $transitionDirection, $translateX: 0) {
	$value: unquote("#{$property}(#{$hintTransitionDistance * $transitionDirection})");
	&:before {
		@include vendor('transform', $value);
	}
	&:after {
		@if $translateX != 0 {
			// For vertical tooltips, we need to animate in y-direction
			// retaining its x-transform.
			@include vendor('transform', translateX($translateX) $value);
		}
		@else {
			@include vendor('transform', $value);
		}
	}
}
