/**
 * source: hint-rounded.scss
 *
 * Defines rounded corner tooltips.
 *
 * Classes added:
 * 	1) hint--rounded
 *
 */
@import "hint-variables";
@import "hint-mixins";

.#{$hintPrefix}rounded {
	&:after {
		border-radius: 4px;
	}
}
