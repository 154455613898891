/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/* Slider */
/* line 3, ./bower_components/slick-carousel/slick/slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 17, ./bower_components/slick-carousel/slick/slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  /* line 24, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-list:focus {
    outline: none; }
  /* line 28, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

/* line 33, ./bower_components/slick-carousel/slick/slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 42, ./bower_components/slick-carousel/slick/slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  /* line 50, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  /* line 56, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-track:after {
    clear: both; }
  /* line 60, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-loading .slick-track {
    visibility: hidden; }

/* line 64, ./bower_components/slick-carousel/slick/slick.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  /* line 68, ./bower_components/slick-carousel/slick/slick.scss */
  [dir="rtl"] .slick-slide {
    float: right; }
  /* line 71, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-slide img {
    display: block; }
  /* line 74, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-slide.slick-loading img {
    display: none; }
  /* line 80, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-slide.dragging img {
    pointer-events: none; }
  /* line 84, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-initialized .slick-slide {
    display: block; }
  /* line 88, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-loading .slick-slide {
    visibility: hidden; }
  /* line 92, ./bower_components/slick-carousel/slick/slick.scss */
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* line 98, ./bower_components/slick-carousel/slick/slick.scss */
.slick-arrow.slick-hidden {
  display: none; }

/* Magnific Popup CSS */
/* line 34, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

/* line 48, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

/* line 60, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

/* line 73, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

/* line 84, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-align-top .mfp-container:before {
  display: none; }

/* line 91, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

/* line 101, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

/* line 108, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-ajax-cur {
  cursor: progress; }

/* line 112, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

/* line 118, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

/* line 125, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-auto-cursor .mfp-content {
  cursor: auto; }

/* line 130, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

/* line 141, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-loading.mfp-figure {
  display: none; }

/* line 160, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-hide {
  display: none !important; }

/* line 171, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  /* line 181, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-preloader a {
    color: #CCC; }
    /* line 183, ./bower_components/magnific-popup/src/css/main.scss */
    .mfp-preloader a:hover {
      color: #FFF; }

/* line 191, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-s-ready .mfp-preloader {
  display: none; }

/* line 198, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-s-error .mfp-content {
  display: none; }

/* line 205, ./bower_components/magnific-popup/src/css/main.scss */
button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

/* line 219, ./bower_components/magnific-popup/src/css/main.scss */
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

/* line 227, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  /* line 245, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  /* line 250, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-close:active {
    top: 1px; }

/* line 255, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-close-btn-in .mfp-close {
  color: #333; }

/* line 261, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

/* line 271, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

/* line 283, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  /* line 293, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow:active {
    margin-top: -54px; }
  /* line 296, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  /* line 300, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  /* line 314, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  /* line 321, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

/* line 329, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-arrow-left {
  left: 0; }
  /* line 331, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  /* line 335, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

/* line 341, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-arrow-right {
  right: 0; }
  /* line 343, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  /* line 347, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

/* line 357, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  /* line 360, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  /* line 365, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

/* line 369, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  /* line 374, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
/* line 394, ./bower_components/magnific-popup/src/css/main.scss */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
/* line 407, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-figure {
  line-height: 0; }
  /* line 409, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  /* line 423, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  /* line 429, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-figure figure {
    margin: 0; }

/* line 433, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

/* line 441, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

/* line 450, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-image-holder .mfp-content {
  max-width: 100%; }

/* line 457, ./bower_components/magnific-popup/src/css/main.scss */
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  /* line 470, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  /* line 475, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  /* line 481, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  /* line 485, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  /* line 490, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    /* line 498, ./bower_components/magnific-popup/src/css/main.scss */
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  /* line 502, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  /* line 506, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  /* line 526, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  /* line 530, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  /* line 534, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  /* line 538, ./bower_components/magnific-popup/src/css/main.scss */
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*-------------------------------------*	HINT.css - A CSS tooltip library
\*-------------------------------------*/
/**
 * HINT.css is a tooltip library made in pure CSS.
 *
 * Source: https://github.com/chinchang/hint.css
 * Demo: http://kushagragour.in/lab/hint/
 *
 */
/**
 * source: hint-core.scss
 *
 * Defines the basic styling for the tooltip.
 * Each tooltip is made of 2 parts:
 * 	1) body (:after)
 * 	2) arrow (:before)
 *
 * Classes added:
 * 	1) hint
 */
/* line 15, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
[class*="hint--"] {
  position: relative;
  display: inline-block;
  /**
	 * tooltip arrow
	 */
  /**
	 * tooltip body
	 */ }
  /* line 19, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"]:before, [class*="hint--"]:after {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transition-delay: 0ms;
    -moz-transition-delay: 0ms;
    transition-delay: 0ms; }
  /* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
    visibility: visible;
    opacity: 1; }
  /* line 44, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms; }
  /* line 52, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"]:before {
    content: '';
    position: absolute;
    background: transparent;
    border: 6px solid transparent;
    z-index: 1000001; }
  /* line 64, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"]:after {
    background: #383838;
    color: white;
    padding: 8px 10px;
    font-size: 12px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 12px;
    white-space: nowrap; }
  /* line 74, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"][aria-label]:after {
    content: attr(aria-label); }
  /* line 79, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
  [class*="hint--"][data-hint]:after {
    content: attr(data-hint); }

/* line 87, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-core.scss */
[aria-label='']:before, [aria-label='']:after,
[data-hint='']:before,
[data-hint='']:after {
  display: none !important; }

/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */
/**
 * set default color for tooltip arrows
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-left:before {
  border-top-color: #383838; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-right:before {
  border-top-color: #383838; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top:before {
  border-top-color: #383838; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-left:before {
  border-bottom-color: #383838; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-right:before {
  border-bottom-color: #383838; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom:before {
  border-bottom-color: #383838; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--left:before {
  border-left-color: #383838; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--right:before {
  border-right-color: #383838; }

/**
 * top tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top:before {
  margin-bottom: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

/**
 * bottom tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom:before {
  margin-top: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

/**
 * right tooltip
 */
/* line 56, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--right:before {
  margin-left: -11px;
  margin-bottom: -6px; }

/* line 64, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--right:after {
  margin-bottom: -14px; }

/* line 69, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--right:before, .hint--right:after {
  left: 100%;
  bottom: 50%; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/**
 * left tooltip
 */
/* line 56, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--left:before {
  margin-right: -11px;
  margin-bottom: -6px; }

/* line 64, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--left:after {
  margin-bottom: -14px; }

/* line 69, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--left:before, .hint--left:after {
  right: 100%;
  bottom: 50%; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/**
 * top-left tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-left:before {
  margin-bottom: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-left:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

/* line 43, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-left:after {
  margin-left: 12px; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

/**
 * top-right tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-right:before {
  margin-bottom: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-right:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

/* line 43, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--top-right:after {
  margin-left: -12px; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/**
 * bottom-left tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-left:before {
  margin-top: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-left:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

/* line 43, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-left:after {
  margin-left: 12px; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

/**
 * bottom-right tooltip
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-right:before {
  margin-top: -11px; }

/* line 22, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-right:before {
  left: calc(50% - 6px); }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

/* line 43, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-position.scss */
.hint--bottom-right:after {
  margin-left: -12px; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/**
 * source: hint-sizes.scss
 *
 * Defines width restricted tooltips that can span
 * across multiple lines.
 *
 * Classes added:
 * 	1) hint--small
 * 	2) hint--medium
 * 	3) hint--large
 *
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-sizes.scss */
.hint--small:after,
.hint--medium:after,
.hint--large:after {
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word; }

/* line 26, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-sizes.scss */
.hint--small:after {
  width: 80px; }

/* line 31, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-sizes.scss */
.hint--medium:after {
  width: 150px; }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-sizes.scss */
.hint--large:after {
  width: 300px; }

/**
 * source: hint-theme.scss
 *
 * Defines basic theme for tooltips.
 *
 */
/* line 10, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-theme.scss */
[class*="hint--"] {
  /**
	 * tooltip body
	 */ }
  /* line 14, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-theme.scss */
  [class*="hint--"]:after {
    text-shadow: 0 -1px 0px black;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); }

/**
 * source: hint-color-types.scss
 *
 * Contains tooltips of various types based on color differences.
 *
 * Classes added:
 * 	1) hint--error
 * 	2) hint--warning
 * 	3) hint--info
 * 	4) hint--success
 *
 */
/**
 * Error
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-color-types.scss */
.hint--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0px #592726; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top-left:before {
  border-top-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top-right:before {
  border-top-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top:before {
  border-top-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom-left:before {
  border-bottom-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom-right:before {
  border-bottom-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--left:before {
  border-left-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--right:before {
  border-right-color: #b34e4d; }

/**
 * Warning
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-color-types.scss */
.hint--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0px #6c5328; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top-left:before {
  border-top-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top-right:before {
  border-top-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top:before {
  border-top-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom-left:before {
  border-bottom-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom-right:before {
  border-bottom-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--left:before {
  border-left-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--right:before {
  border-right-color: #c09854; }

/**
 * Info
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-color-types.scss */
.hint--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0px #1a3c4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top-left:before {
  border-top-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top-right:before {
  border-top-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top:before {
  border-top-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom-left:before {
  border-bottom-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom-right:before {
  border-bottom-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--left:before {
  border-left-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--right:before {
  border-right-color: #3986ac; }

/**
 * Success
 */
/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-color-types.scss */
.hint--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0px #1a321a; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top-left:before {
  border-top-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top-right:before {
  border-top-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top:before {
  border-top-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom-left:before {
  border-bottom-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom-right:before {
  border-bottom-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom:before {
  border-bottom-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--left:before {
  border-left-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--right:before {
  border-right-color: #458746; }

/**
 * source: hint-always.scss
 *
 * Defines a persisted tooltip which shows always.
 *
 * Classes added:
 * 	1) hint--always
 *
 */
/* line 14, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-always.scss */
.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top-left:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top-left:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top-right:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--top-right:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom-left:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom-left:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom-right:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--bottom-right:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--left:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--always.hint--right:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/**
 * source: hint-rounded.scss
 *
 * Defines rounded corner tooltips.
 *
 * Classes added:
 * 	1) hint--rounded
 *
 */
/* line 14, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-rounded.scss */
.hint--rounded:after {
  border-radius: 4px; }

/**
 * source: hint-effects.scss
 *
 * Defines various transition effects for the tooltips.
 *
 * Classes added:
 * 	1) hint--no-animate
 * 	2) hint--bounce
 *
 */
/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-effects.scss */
.hint--no-animate:before, .hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-effects.scss */
.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }

/**
 * based on hint.css with html content support
 */
/* line 12, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html.hint:after {
  display: none;
  opacity: 0; }

/* line 16, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint__content {
  background: #383838;
  color: white;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
  pointer-events: none; }

/* line 25, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--hoverable .hint__content {
  pointer-events: auto; }

/* line 28, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html {
  position: relative;
  display: inline-block;
  /**
   * tooltip arrow
   */ }
  /* line 32, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
  .hint--html:before, .hint--html:after {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transition-delay: 0ms;
    -moz-transition-delay: 0ms;
    transition-delay: 0ms; }
  /* line 52, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
  .hint--html:hover:before, .hint--html:hover:after {
    visibility: visible;
    opacity: 1; }
  /* line 56, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
  .hint--html:hover:before, .hint--html:hover:after {
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms; }
  /* line 63, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
  .hint--html:before {
    content: '';
    position: absolute;
    background: transparent;
    border: 6px solid transparent;
    z-index: 1000001; }

/* line 73, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html .hint__content {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms; }

/* line 93, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html:hover .hint__content,
.hint--html:focus .hint__content {
  visibility: visible;
  opacity: 1; }

/* line 99, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html:hover .hint__content {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms; }

/* line 104, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html.hint--notrans:before, .hint--html.hint--notrans:after {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

/* line 106, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html.hint--notrans .hint__content {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

/**
 * top tooltip
 */
/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top .hint__content {
  bottom: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top:before, .hint--top .hint__content {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top:hover .hint__content, .hint--top:focus .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left .hint__content {
  bottom: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left:before, .hint--top-left .hint__content {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

/* line 139, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left:before {
  margin-left: 12px; }

/* line 142, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left .hint__content {
  margin-left: 24px; }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-left:hover .hint__content, .hint--top-left:focus .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right .hint__content {
  bottom: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right:before, .hint--top-right .hint__content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

/* line 139, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right:before {
  margin-left: -12px; }

/* line 142, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right .hint__content {
  margin-left: -24px; }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--top-right:hover .hint__content, .hint--top-right:focus .hint__content {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/**
 * bottom tooltip
 */
/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom .hint__content {
  top: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom:before, .hint--bottom .hint__content {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom:hover .hint__content, .hint--bottom:focus .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left .hint__content {
  top: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left:before, .hint--bottom-left .hint__content {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

/* line 139, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left:before {
  margin-left: 12px; }

/* line 142, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left .hint__content {
  margin-left: 24px; }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-left:hover .hint__content, .hint--bottom-left:focus .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right .hint__content {
  top: 100%;
  left: 50%; }

/* line 136, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right:before, .hint--bottom-right .hint__content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

/* line 139, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right:before {
  margin-left: -12px; }

/* line 142, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right .hint__content {
  margin-left: -24px; }

/* line 147, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

/* line 160, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--bottom-right:hover .hint__content, .hint--bottom-right:focus .hint__content {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/**
 * right tooltip
 */
/* line 165, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--right:before {
  margin-left: -12px;
  margin-bottom: -6px; }

/* line 170, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--right .hint__content {
  margin-bottom: -14px; }

/* line 174, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--right .hint__content {
  left: 100%;
  bottom: 50%; }

/* line 179, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--right .hint__content:after {
  content: ' ';
  position: absolute;
  left: -12px;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red; }

/* line 192, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--right:hover .hint__content, .hint--right:focus .hint__content {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/**
 * left tooltip
 */
/* line 165, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--left:before {
  margin-right: -12px;
  margin-bottom: -6px; }

/* line 170, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--left .hint__content {
  margin-bottom: -14px; }

/* line 174, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--left .hint__content {
  right: 100%;
  bottom: 50%; }

/* line 179, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--left .hint__content:after {
  content: ' ';
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red; }

/* line 192, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--left:hover .hint__content, .hint--left:focus .hint__content {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/**
 * Error
 */
/* line 238, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--error .hint__content {
  background-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top-left:before {
  border-top-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top-right:before {
  border-top-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--top:before {
  border-top-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom-left:before {
  border-bottom-color: #b34e4d; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom-right:before {
  border-bottom-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--left:before {
  border-left-color: #b34e4d; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--error.hint--right:before {
  border-right-color: #b34e4d; }

/**
 * Warning
 */
/* line 238, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--warning .hint__content {
  background-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top-left:before {
  border-top-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top-right:before {
  border-top-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--top:before {
  border-top-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom-left:before {
  border-bottom-color: #c09854; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom-right:before {
  border-bottom-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--left:before {
  border-left-color: #c09854; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--warning.hint--right:before {
  border-right-color: #c09854; }

/**
 * Info
 */
/* line 238, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--info .hint__content {
  background-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top-left:before {
  border-top-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top-right:before {
  border-top-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--top:before {
  border-top-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom-left:before {
  border-bottom-color: #3986ac; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom-right:before {
  border-bottom-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--left:before {
  border-left-color: #3986ac; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--info.hint--right:before {
  border-right-color: #3986ac; }

/**
 * Success
 */
/* line 238, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--success .hint__content {
  background-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top-left:before {
  border-top-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top-right:before {
  border-top-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--top:before {
  border-top-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom-left:before {
  border-bottom-color: #458746; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom-right:before {
  border-bottom-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--bottom:before {
  border-bottom-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--left:before {
  border-left-color: #458746; }

/* line 23, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/hint-css/hint-mixins.scss */
.hint--success.hint--right:before {
  border-right-color: #458746; }

/* line 273, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html.hint--hidden:hover:after, .hint--html.hint--hidden:hover:before {
  opacity: 0;
  visibility: hidden; }

/* line 277, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--html.hint--hidden:hover .hint__content {
  opacity: 0;
  visibility: hidden; }

/* line 282, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible; }

/* line 286, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always .hint__content {
  opacity: 1;
  visibility: visible; }

/* line 291, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--top .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

/* line 295, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--top-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

/* line 299, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--top-right .hint__content {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/* line 303, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--bottom .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

/* line 307, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--bottom-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

/* line 311, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--bottom-right .hint__content {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/* line 315, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--left .hint__content {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/* line 319, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint.sass */
.hint--always.hint--right .hint__content {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/* line 3, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintHtml {
  composes: hint--html; }

/* line 6, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintContent {
  composes: hint__content; }

/* line 9, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintHoverable {
  composes: hint--hoverable; }

/* line 12, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintAlways {
  composes: hint--always; }

/* line 15, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintInfo {
  composes: hint--info; }

/* line 18, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintError {
  composes: hint--error; }

/* line 21, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintWarning {
  composes: hint--warning; }

/* line 24, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintSuccess {
  composes: hint--success; }

/* line 27, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintHidden {
  composes: hint--hidden; }

/* line 30, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintTop {
  composes: hint--top; }

/* line 33, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintTopRight {
  composes: hint--top-right; }

/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintTopLeft {
  composes: hint--top-left; }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintBottom {
  composes: hint--bottom; }

/* line 42, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintBottomRight {
  composes: hint--bottom-right; }

/* line 45, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/html-hint/html-hint-cc.sass */
.hintBottomLeft {
  composes: hint--bottom-left; }

/*
// ----------------------------------------------
// Usage example:
// For IE set $mq-support to false.
// Set the fixed value.
// Then use mixins to test whether styles should be applied.
// ----------------------------------------------

$mq-support: false;
$mq-fixed-value: 1024;

// Renders at fixed value
@include bp (min-width, 300px) { 
    div { color:#000; }
}

// Doesn't render without MQ support
@include bp (min-width, 1200px) { 
    div { color:#FFF; }
}

// Doesn't render without MQ support
@include bp (max-width, 300px) { 
    div { color:#444; }
}

// Renders at fixed value
@include bp (max-width, 1200px) { 
    div { color:#888; }
}

// ----------------------------------------------
*/
/* ============================================ *
 * Homepage
 * ============================================ */
/* -------------------------------------------- *
 * Primary Banner
 */
/* line 36, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
body.cms-home .main-container {
  padding-top: 20px; }

/* line 40, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
body.cms-home .slideshow-container {
  margin-top: 0; }

/* line 45, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.slideshow .banner-msg {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  top: 30%;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; }

/* line 54, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.slideshow .banner-msg h2 {
  color: #FFFFFF;
  font-size: 24px;
  text-shadow: 1px 1px 3px false #555555; }

/* line 60, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.slideshow .banner-msg h2 strong {
  font-weight: bold;
  display: block;
  font-size: 36px; }

@media only screen and (max-width: 770px) {
  /* line 68, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .slideshow .banner-msg h2 {
    font-size: 20px; }
  /* line 72, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .slideshow .banner-msg h2 strong {
    font-size: 24px; } }

@media only screen and (max-width: 479px) {
  /* line 79, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .slideshow .banner-msg {
    top: 20%; }
  /* line 83, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .slideshow .banner-msg h2 {
    font-size: 14px; }
  /* line 87, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .slideshow .banner-msg h2 strong {
    font-size: 16px; } }

/* -------------------------------------------- *
 * Promotion Banner Section
 */
/* line 96, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos {
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%; }

/* line 102, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos:after {
  content: '';
  display: table;
  clear: both; }

/* Specifying the body only in order to override the .std ul li styling */
/* line 108, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
body .promos > li {
  margin: 0 0 10px 0;
  list-style: none;
  text-align: center;
  position: relative;
  border: 1px solid #CCCCCC;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; }

/* line 117, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos > li:last-child {
  margin-bottom: 0; }

/* line 121, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos img {
  max-width: 100%;
  width: 100%; }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos a:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: block; }

/* line 131, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos span {
  color: #FFFFFF;
  text-transform: uppercase;
  position: absolute;
  display: block;
  width: 100%;
  top: 10%;
  font-weight: 500;
  font-size: 20px;
  font-family: "Open Sans", Verdana, Arial, sans-serif;
  text-shadow: 1px 1px 3px false #555555; }

/* line 144, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.promos strong {
  font-weight: 600;
  font-size: 26px;
  display: block; }

@media only screen and (min-width: 771px) {
  /* line 152, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .promos span {
    font-size: 16px; }
  /* line 156, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .promos strong {
    font-size: 17px; }
  /* Config: Three columns + flexible gutter */
  /* line 163, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  body .promos > li {
    float: left;
    width: 31.74603%;
    margin-right: 2.38095%; }
  /* line 169, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .promos > li:nth-child(3n) {
    margin-right: 0; } }

@media only screen and (min-width: 880px) {
  /* line 175, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .promos span {
    font-size: 18px; }
  /* line 179, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
  .promos strong {
    font-size: 24px; } }

/* -------------------------------------------- *
 * New Products Section
 */
/* Hiding the reviews content via CSS because we can't modify the template, */
/* since it may be used in other places where those elements are needed */
/* line 190, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.cms-index-index .products-grid .ratings,
.cms-index-index .products-grid .actions,
.cms-index-noroute .products-grid .ratings,
.cms-index-noroute .products-grid .actions {
  display: none; }

/* line 197, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.cms-index-index h2.subtitle {
  padding: 6px 0;
  text-align: center;
  color: #3399CC;
  font-weight: 600;
  border-bottom: 1px solid #CCCCCC;
  border-top: 1px solid #CCCCCC; }

/* line 206, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_home.scss */
.cms-index-noroute h2.subtitle {
  display: none; }

/* ============================================ *
 * Category Landing Pages
 * ============================================ */
/* line 30, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.category-image {
  position: relative; }

/* line 34, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.categorylandingMainblock {
  border: 1px solid #E2E2E2;
  padding: 7px; }

/* line 39, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks {
  float: left;
  padding: 0 0 20px 0;
  width: 100%; }

/* line 45, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks li {
  position: relative;
  float: left !important;
  width: 23% !important;
  margin-right: 2.66667% !important;
  margin-bottom: 10px;
  border: 1px solid #CCCCCC;
  padding: 10px; }

/* line 55, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks li:hover {
  border-color: #3399CC; }

@media only screen and (max-width: 770px) {
  /* line 60, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
  .catblocks li {
    width: 49% !important;
    margin-right: 2% !important; }
  /* line 65, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
  .catblocks li:nth-child(even) {
    margin-right: 0 !important; } }

@media only screen and (max-width: 479px) {
  /* line 71, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
  .catblocks li {
    width: 100% !important;
    margin-right: 0 !important;
    float: none; } }

/* line 78, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks li:last-child {
  margin-right: 0 !important; }

/* line 82, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks li img {
  width: 100%;
  max-width: 100%; }

/* line 87, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocks li a span {
  color: #FFFFFF;
  position: absolute;
  background-color: #7B7C7B;
  font-family: raleway;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 5px 10px;
  margin-right: 10px; }

/* line 103, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catblocklinks a {
  color: #FFFFFF;
  text-decoration: none; }

/* line 108, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catlandingbottomPromoblock {
  float: left;
  width: 100%;
  border: 1px solid #E2E2E2;
  margin-bottom: 100px; }

/* line 115, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption {
  display: block;
  margin: 0;
  position: absolute;
  color: #000000;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center; }

/* line 126, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption span.blue-big-text {
  color: #3399CC;
  font-size: 50px;
  font-weight: 800;
  padding: 0 0 0 8px; }

/* line 133, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption span.blue-big-text {
  padding: 0 !important; }

/* line 137, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption span.three-dots {
  background: url(../images/bg_threeDots.png) no-repeat center center;
  display: block;
  height: 20px; }

/* line 143, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption p.desc {
  font: italic 18px/28px Georgia, "Times New Roman", Times, serif;
  color: #656565; }

/* line 148, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption a.link:hover,
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.link:hover {
  text-decoration: underline;
  color: #1189C5; }

/* line 154, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.blue-big-text,
.catalog-category-view div.categoryland-caption span.blue-big-text {
  color: #3399CC;
  font-size: 50px;
  font-weight: 800;
  padding: 0 0 0 8px; }

/* line 162, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.catalog-category-view div.categoryland-caption span.blue-big-text {
  padding: 0 !important; }

/* line 166, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.three-dots,
.catalog-category-view div.categoryland-caption span.three-dots {
  background: url(../images/bg_threeDots.png) no-repeat center center;
  display: block;
  height: 20px; }

/* line 173, /Users/Andrea/Sites/flexo24/httpdocs/skin/frontend/flexo/default/src/scss/content/_category.scss */
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption p.desc,
.catalog-category-view div.categoryland-caption p.desc {
  font: italic 18px/28px Georgia, "Times New Roman", Times, serif;
  color: #656565; }
