@import './html-hint.sass';

.hintHtml {
  composes: hint--html; }

.hintContent {
  composes: hint__content; }

.hintHoverable {
  composes: hint--hoverable; }

.hintAlways {
  composes: hint--always; }

.hintInfo {
  composes: hint--info; }

.hintError {
  composes: hint--error; }

.hintWarning {
  composes: hint--warning; }

.hintSuccess {
  composes: hint--success; }

.hintHidden {
  composes: hint--hidden; }

.hintTop {
  composes: hint--top; }

.hintTopRight {
  composes: hint--top-right; }

.hintTopLeft {
  composes: hint--top-left; }

.hintBottom {
  composes: hint--bottom; }

.hintBottomRight {
  composes: hint--bottom-right; }

.hintBottomLeft {
  composes: hint--bottom-left; }
