/**
 * source: hint-theme.scss
 *
 * Defines basic theme for tooltips.
 *
 */
@import "hint-variables";
@import "hint-mixins";

[class*="#{$hintPrefix}"] {
	/**
	 * tooltip body
	 */
	&:after {
		text-shadow: 0 -1px 0px darken($hintDefaultColor, $hintTextShadowDarkenAmount);
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
	}
}